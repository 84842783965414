import Filterrific from './filterrific'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Splitting from 'splitting'

document.addEventListener("turbolinks:load", function() {

  if(document.getElementById('filterrific_filter')!=null){
    Filterrific.init()
  }
  setTimeout(() => {
    document.body.classList.add("loaded")
  }, 100)

  // Just for the Home Page
  if(document.getElementsByClassName('pages-home').length > 0) {
    gsap.registerPlugin(ScrollTrigger);

    // Home page hero text animation
    const target = '.hero-in'
    if(document.getElementsByClassName(target).length > 0) {
      const tl1 = gsap.timeline().set(target,{ opacity: 0 })
      tl1.to(target, { opacity: 0, duration: 0.3 })
      tl1.to(target, { opacity: 1, duration: 1.5 })
      const tl2 = gsap.timeline().set(target,{ transform: "translateX(-50%) scale(0.1)" })
      tl2.to(target, { duration: 0.3 })
      tl2.to(target, { transform: "translateX(0) scale(1)", duration: 1.5 })
    }

    // Home page images fade in
    const images = document.querySelectorAll('.card-img-top')
    images.forEach((image) => {
      gsap.from(image, {
        scrollTrigger: {
          trigger: image,
          start: '20px 80%'
        },
        opacity: 0.1,
        duration: 2
      })
    })
  }
  Splitting()
})

document.addEventListener("turbolinks:before-cache", function() {
  // Hide hero text
  document.body.classList.remove("loaded")
  // Hide menu
  document.getElementById('nav-toggle').checked = false
})

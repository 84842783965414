document.addEventListener("turbolinks:load", function() {
  const body = document.querySelector('body');
  function isScrolled() {
    const scrollPos = window.scrollY;
    if (scrollPos > 96) {
      body.classList.add('scrolled')
    } else {
      body.classList.remove('scrolled')
    }
  }
  window.addEventListener('scroll', function() {
    isScrolled();
  })
  isScrolled();
});